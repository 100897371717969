import React from 'react';
import { PropsWithTestId, UniqueIdentifier } from '../../../../../../types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { ScrollingLogosDisplayStyle } from './ScrollingLogosDisplayStyle';
import { LogosDisplayStyle } from './LogosDisplayStyle';
import { LogosAndTextDisplayStyle } from './LogosAndTextDisplayStyle';
import { SponsorsModuleType, SponsorsModuleStyles } from './types';

export interface SponsorCategory {
  displayType: string;
  eventId: UniqueIdentifier;
  sponsorCategoryIds: UniqueIdentifier[];
}

export interface SponsorsModuleProps {
  module: SponsorsModuleType;
}

export default function SponsorsModule(
  props: PropsWithTestId<SponsorsModuleProps>,
) {
  const {
    module: { '@type': type, style, elementId, visualStyle },
    testId,
  } = props;

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      {style === SponsorsModuleStyles.SCROLLING_LOGOS && (
        <ScrollingLogosDisplayStyle {...props.module} />
      )}
      {style === SponsorsModuleStyles.LOGOS && (
        <LogosDisplayStyle {...props.module} />
      )}
      {style === SponsorsModuleStyles.LOGOS_AND_TEXT && (
        <LogosAndTextDisplayStyle {...props.module} />
      )}
    </HubModuleWrapper>
  );
}

SponsorsModule.defaultProps = {
  testId: 'hub-sponsors-module',
};
