import { HubModule, HubModuleCTA } from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { HubSponsorsDiscoveryBlock } from '../../../../../../store/features/discovery';
import { SponsorCategory } from './index';

export enum SponsorsModuleStyles {
  SCROLLING_LOGOS = 'SCROLLING_LOGOS',
  LOGOS = 'LOGOS',
  LOGOS_AND_TEXT = 'LOGOS_AND_TEXT',
}

export enum SponsorsModuleTitleTypes {
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}

export enum SponsorsModuleRotateTypes {
  SINGLE = 'SINGLE',
  SMOOTH = 'SMOOTH',
  PAGE = 'PAGE',
}

export enum SponsorsModuleLayoutTypes {
  FULLSCREEN = 'FULLSCREEN',
}

export interface SponsorsModuleType extends HubModule {
  '@type': HubModuleTypes.SponsorCategory;
  link: HubModuleCTA;
  rotateType: SponsorsModuleRotateTypes;
  style: SponsorsModuleStyles;
  title: string;
  titleType: SponsorsModuleTitleTypes;
  layoutType: SponsorsModuleLayoutTypes;
  customTitle: string;
  sponsorsCategories: SponsorCategory[];
  sponsorsFromDiscovery: HubSponsorsDiscoveryBlock[];
}
