import React, { useMemo } from 'react';
import { ModuleHeader } from '../../../components';
import { SponsorsModuleType } from '../types';
import s from '../LogosAndTextDisplayStyle/LogosAndTextDisplayStyle.module.scss';
import SponsorCard from '../../../../productFilter/cards/SponsorCard';
import { PropsWithTestId } from '../../../../../../../types';
import { CallToAction } from '../../../../CallToActionGroup';
import { generateDomainUrl } from '../../../../../../../helpers/generateDomainUrl';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';

export interface LogosDisplayStyleProps extends SponsorsModuleType {}

export function LogosDisplayStyle(
  props: PropsWithTestId<LogosDisplayStyleProps>,
) {
  const { title, link, sponsorsFromDiscovery = [], testId } = props;
  const { tenantsConfig: domains } = useRootSelector(selectPageConfig);
  const sitesWithSponsors = useMemo(() => {
    return sponsorsFromDiscovery.filter(
      (spexBlock) => spexBlock.spexs.length > 0,
    );
  }, [sponsorsFromDiscovery]);

  return (
    <div className="container" data-testid={testId}>
      {title && (
        <div className="row">
          <div className="col-xs-12">
            <ModuleHeader title={title} lineAfterHeading />
          </div>
        </div>
      )}
      {sitesWithSponsors.map((sponsorBlock, index) => (
        <div className={s.siteOffset} key={index}>
          <div className="row">
            <div className="col-xs-12">
              <ModuleHeader title={sponsorBlock.siteName} lineAfterHeading />
            </div>
          </div>
          <div className="row">
            {sponsorBlock.spexs.map((sponsor, sponsorIndex) => {
              const path = `${sponsorBlock.sitePath}/sponsors/${sponsor.path}`;
              const data = { ...sponsor, name: '', categoryName: '' };
              const url = generateDomainUrl({
                domains,
                domain: sponsorBlock.domain,
                tenant: sponsorBlock.tenant,
                path,
              });

              return (
                <div
                  className={`col-xs-6 col-sm-4 col-md-3 ${s.sponsorOffset}`}
                  key={sponsorIndex}
                >
                  <SponsorCard data={data} url={url} onlyLogo />
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-xs-12">
          {link?.type && (
            <CallToAction
              link={link}
              variant="contained"
              align="center"
              data-testid="hub-module-link"
            />
          )}
        </div>
      </div>
    </div>
  );
}

LogosDisplayStyle.defaultProps = {
  testId: 'hub-sponsors-module-logos',
};
