import React, { HTMLAttributes } from 'react';
import { PropsWithTestId } from '../../../../../../types';
import {
  CardAnchor,
  Badge,
  Image,
  ImageProps,
  Title,
  TitleProps,
  BadgeProps,
} from '../components';
import { DiscoveryBrandSponsorOrganisation } from '../../../../../../store/features/discovery';
import styles from './SponsorCard.module.scss';
import { CardWrapper } from '../components/CardWrapper';

export interface SponsorCardProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  data: DiscoveryBrandSponsorOrganisation;
  url: string;
  onlyLogo: boolean;
}

export default function SponsorCard(props: PropsWithTestId<SponsorCardProps>) {
  const {
    data: { name, photo, categoryName },
    testId,
    url,
    onlyLogo,
  } = props;

  const getImage = (style?: ImageProps['imageStyle']) => (
    <Image imageStyle={style} path={photo} className={styles.image} />
  );

  const getCategory = (size?: BadgeProps['size']) =>
    categoryName && (
      <Badge
        testId="video-card-description"
        size={size}
        className={styles.company}
      >
        {categoryName}
      </Badge>
    );

  const getTitle = (
    size?: TitleProps['size'],
    className?: TitleProps['className'],
  ) => (
    <>
      {name && (
        <Title size={size} className={className}>
          {name}
        </Title>
      )}
      {getCategory()}
    </>
  );

  return (
    <CardAnchor to={url} data-testid={testId}>
      <CardWrapper className={styles.wrapper}>
        <div className="row">
          <div className="col-xs-12">{getImage()}</div>
          {!onlyLogo && (
            <div className="col-xs-12">
              <div className={styles.body}>{getTitle()}</div>
            </div>
          )}
        </div>
      </CardWrapper>
      <div itemScope itemType="https://schema.org/Sponsor">
        <meta property="og:name" content={name} />
        <meta property="og:path" content={url} />
      </div>
    </CardAnchor>
  );
}

SponsorCard.defaultProps = {
  testId: 'sponsor-card',
  onlyLogo: false,
};
